import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { apiAuthInstance } from '../../apis/backend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEnvelopeOpenText, faPen, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import TableList from '../../components/shared/TableList';
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning";
import LoadingButton from '../../components/shared/LoadingButton'
import ModalApiStatus from "../../components/modal/ModalApiStatus"
import FreePaymentModal from "../../components/modal/FreePaymentModal"

export default function EmailSettings(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('EmailSettings', 'common')

    const isFree = settings.state.authData.is_free
    const userRank = settings.state.authData.auth_rank

    const initModalData = {
        "name": "",
        "reply_to": "",
        "from_name": "",
    }
    const initMailSettingPairsData = {
        "name": "",
        "reply_to": "",
        "from_name": "",
    }
    const initModalAction = {
        "type": "",
        "targetId": undefined
    }
    const initModal = {
        "data": initModalData,
        "action": initModalAction,
        "loaded": true,
        "show": false,
        "warningModalShow": false
    }
    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }
    const [mailSettingPairs, setMailSettingPairs] = useState([])
    const [mailSettingPairsData, setMailSettingPairsData] = useState(initMailSettingPairsData)
    const [modal, setModal] = useState(initModal);
    const [apiStatus, setApiStatus] = useState(initApiStatus)
    const [freePaymentModalShow, setFreePaymentModalShow] = useState(false)


    const handleSubmitModalClose = (value) =>{
        // setModalSubmit(initModalSubmit);
        // if (!value)  setModalShow(true);
    }
    const handleModalShow = (event) => {
        if (!isFree || (isFree && userRank==='developer')) {
            let selectedTestMemeberData = undefined
            switch (event.target.name) {
                case "create":
                    setModal(prevState => ({
                        ...prevState,
                        "data": initModalData,
                        "action": {
                            ...initModalAction,
                            "type": "create"
                        },
                        "show": true,
                        "warningModalShow": false,
                    }));
                    break;

                case "edit":
                    selectedTestMemeberData = mailSettingPairs.find(testMember => testMember.id === parseInt(event.target.value));
                    setModal(prevState => ({
                        ...prevState,
                        "action": {
                            "type": "edit",
                            "targetId": event.target.value
                        },
                        "show": true,
                        "warningModalShow": false
                    }));
                    setMailSettingPairsData({
                        "id": selectedTestMemeberData.id,
                        "name": selectedTestMemeberData.name,
                        "reply_to": selectedTestMemeberData.reply_to,
                        "from_name": selectedTestMemeberData.from_name,
                    })
                    break;

                case "delete":
                    selectedTestMemeberData = mailSettingPairs.find(testMember => testMember.id === parseInt(event.target.value));
                    setModal(prevState => ({
                        ...prevState,
                        "data": {
                            ...initModalData,
                            "id": selectedTestMemeberData.id
                        },
                        "action": {
                            "type": "delete",
                            "targetId": event.target.value
                        },
                        "show": false,
                        "warningModalShow": true
                    }));
                    break;

                case "editFooterSetting":
                    setModal(prevState => ({
                        ...prevState,
                        "action": {
                            "type": "editFooterSetting",
                            
                        },
                        "show": true,
                        "warningModalShow": false,
                        
                    }),
                    getFooterSetting()
                    )
                    break;

                default:
                    //pass
                    break;
            }
        } else {
            setFreePaymentModalShow(true)
        }
    }
    const handleModalClose = () => {
        setModal(prevState => ({
            ...prevState,
            "data": initModalData,
            "action": initModalAction,
            "show": false,
            "warningModalShow": false,
            "loaded": true,
        }));
        setMailSettingPairsData(initMailSettingPairsData);
        setApiStatus(initApiStatus)
        setFreePaymentModalShow(false)
    }

    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                }));
                break;

            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                }));
                break;

            case "editFooterSetting":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                }));
                break;

            default:
                //pass
                break;
        }
    }

    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false,

                }));
                break;
            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false,

                }));
                break;
            case "delete":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                }));
                break;
            case "editFooterSetting":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                }));
                break;
            default:
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                }));
                break;
        }
    }
    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                createTestMember()
                break
            case "edit":
                putTestMember()
                break
            case "delete":
                deleteTestMember()
                break
            case "editFooterSetting":
                putFooterSetting()
                break
            default:
                //pass
                break;
        }
    }

    async function getTestMembers() {
        await apiAuthInstance({
            "url": "email/settings/sender/",
            "method": "get"
        }).then((response) => {
            setMailSettingPairs(response.data.data);
        });
    }
    

    async function createTestMember() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "email/settings/sender/",
            "method": "post",
            "data":{
                "name": mailSettingPairsData.name,
                "reply_to": mailSettingPairsData.reply_to,
                "from_name": mailSettingPairsData.from_name,
            }
        }).then((response) => {
            getTestMembers()
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "efficiencyModal": false
            }));
            setMailSettingPairsData(initMailSettingPairsData);
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        }).catch((error) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                warningModalShow: false,
                efficiencyModal: false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        });
    }

    async function putTestMember() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "email/settings/sender/" + modal.action.targetId + "/",
            "method": "put",
            "data":{
                "name": mailSettingPairsData.name,
                "reply_to": mailSettingPairsData.reply_to,
                "from_name": mailSettingPairsData.from_name,
            }
        }).then((response) => {
            getTestMembers()
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "efficiencyModal": false
            }));
            setMailSettingPairsData(initMailSettingPairsData);
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        }).catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        });
    }

    async function deleteTestMember() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "email/settings/sender/" + modal.action.targetId + "/",
            "method": "delete"
        }).then((response) => {
            getTestMembers()
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "efficiencyModal": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            });
        }).catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        });;
    }

    useEffect(() => {
        getTestMembers()
    }, []);

    // const [footerModalshow, setFooterModalShow] = useState(false);

    // const handleFooterModalClose = () => setFooterModalShow(false);
    // const handleFooterModalShow = () => setFooterModalShow(true);

    const initialFooterSetting = {
        company: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    }
    const [footerSetting, setFooterSetting] = useState(initialFooterSetting)
    const handleFooterSettingChange = (e) => setFooterSetting({ ...footerSetting, [e.target.name]: e.target.value })
    const len = (str) => {
        let length = 0;
        const char050 = "·,./;’[]=-/*-!@#$%^&*()_{}:\"<>?1234567890fijlrIt "
        for (const i of str) {
            const ascCh = i.charCodeAt()
            if (char050.indexOf(i) !== -1) {
                length += 0.5;
            } else if (ascCh >= 48 && ascCh <= 57) {
                length += 0.55;
            } else if (ascCh >= 97 && ascCh <= 122) { // a~z
                length += 0.5;
            } else if (ascCh >= 65 && ascCh <= 90) { // A~Z
                length += 0.6;
            } else {
                length++;
            }
            }
            return Math.ceil(length * 2);
        }

    async function getFooterSetting() {
        await apiAuthInstance({
            "url": "email/settings/footer/",
            "method": "get",
        }).then((response) => {
            setFooterSetting({
                ...initialFooterSetting,
                company: response.data.company,
                address: response.data.address,
                city: response.data.city,
                state: response.data.state,
                zip: response.data.zip_code,
                country: response.data.country,
            })
        })
    }
    async function putFooterSetting() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "email/settings/footer/",
            "method": "put",
            "data": {
                "company": footerSetting.company,
                "address": footerSetting.address,
                "city": footerSetting.city,
                "state": footerSetting.state,
                "zip_code": footerSetting.zip,
                "country": footerSetting.country,
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "efficiencyModal": false
            }));
            setFooterSetting(initialFooterSetting);
        })
    }

    return (
        <>
            <div className="d-flex flex-column h-100">
                <Row xs="auto" className="mb-2 mailSendingTabs">
                    <div className="d-flex justify-content-start w-100">
                        <Button variant="orcaLight" name="create" onClick={handleModalShow}>
                            <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                {t('create_fromname_replyto')}
                        </Button>
                        {/* <Button className="ms-2" variant="orcaLight" name="editFooterSetting" onClick={handleModalShow}>
                            <FontAwesomeIcon icon={faEdit} style={{ pointerEvents: 'none' }} className="pe-2" />
                                {t('edm_footer_setting')}
                        </Button> */}
                    </div>  
                </Row>
                <ModalSubmitWarning settings={{
                    submitButton: {
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "Submit",
                        "loaded": modal.loaded
                    },
                    "warningModalShow": modal.warningModalShow,
                    "handleWarningModalCancel": handleWarningModalCancel,
                    "handleModalSubmit": handleWarningModalSubmit,
                    "handleModalClose": handleModalClose,
                    "warningMessageType": modal.action.type,
                }}
                />
                <TableList settings={{
                    tableConfig: [
                        {
                            "head": "name",
                            "dataType": "text-center",
                            "fieldName": "name"
                        },
                        {
                            "head": "from_name",
                            "dataType": "text-center",
                            "fieldName": "from_name"
                        },
                        {
                            "head": "reply_to",
                            "dataType": "text-center",
                            "fieldName": "reply_to"
                        },
                        {
                            "head": "functions",
                            "dataType": "update and delete",
                            "fieldAsValue": "id",
                            "onUpdateClick": handleModalShow,
                            "onDeleteClick": handleModalShow
                        }
                    ],
                    "data": mailSettingPairs,
                    "size": 10,
                    "pagination": true,
                }}
                />
                <ModalApiStatus
                    settings={{
                    content: {
                        status: apiStatus.status,
                        message: apiStatus.message,
                    },
                    apiStatusModalShow: apiStatus.apiStatusModalShow,
                    handleModalClose,
                    }}
                />
                <Modal show={modal.show && modal.action.type !== 'editFooterSetting'} size="lg">
                    <Modal.Header closeButton onHide={handleModalClose}>
                        { modal.action.type === 'create' && <Modal.Title>{t('create_fromname_replyto')}</Modal.Title> }
                        { modal.action.type === 'edit' && <Modal.Title>{t('edit_fromname_replyto')}</Modal.Title> }
                        {/* <Modal.Title>{modal.action.type} the new reply to & from name</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body className="d-flex px-5">
                        <Row className="h-100 w-100 m-0">
                            <Col xs={4} className="d-flex text-orcaLighter">
                                <FontAwesomeIcon className="my-auto ms-auto" style={{ height: '6rem' }} icon={faEnvelopeOpenText} />
                                <span className="my-auto me-auto"><FontAwesomeIcon className="pt-5" style={{ height: '3.5rem' }} icon={faPen} /></span>
                            </Col>
                            <Col xs={8} className="pt-2 MailCampaignPreview d-flex flex-column">
                                <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
                                    <Form.Label>{t('fromname_replyto_name')}</Form.Label>
                                    <Form.Control maxLength="40" name="testMemberName" type="text" placeholder="為您的寄件設定命名" defaultValue={mailSettingPairsData.name} onChange={(event) => setMailSettingPairsData({ ...mailSettingPairsData, 'name': event.target.value })} />
                                </Form.Group>
                                <Form.Group className="w-100 mb-3" controlId="formBasicReplyTo">
                                    <Form.Label>{t('fromname')}</Form.Label>
                                    <Form.Control name="testMembePhone" type="from_name" placeholder="ex. ORCA MA+" value={mailSettingPairsData.from_name} onChange={(event) => setMailSettingPairsData({ ...mailSettingPairsData, 'from_name': event.target.value })} />
                                </Form.Group>
                                <Form.Group className="w-100 mb-3" controlId="formBasicFromName">
                                    <Form.Label>{t('replyto')}</Form.Label>
                                    <Form.Control name="testMembeEmail" type="reply_to" placeholder="ex. yourmail@email.com" defaultValue={mailSettingPairsData.reply_to} onChange={(event) => setMailSettingPairsData({ ...mailSettingPairsData, 'reply_to': event.target.value })} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            {t('cancel', { ns: 'common' })}
                        </Button>
                        <LoadingButton settings={{
                            "name": modal.action.type,
                            "value": modal.action.targetId,
                            "content": "提交",
                            "loaded": modal.loaded,
                            "onClick": handleModalSubmit
                        }} />
                    </Modal.Footer>
                </Modal>

                <Modal show={modal.show && modal.action.type === 'editFooterSetting'} size="xl">
                <Modal.Header closeButton={true} onHide={handleModalClose}>
                    <h4 className="mb-0 ">{t('edm_footer_setting')}</h4>
                </Modal.Header>
                <Modal.Body className="py-4 px-5">
                    <Row className="px-3">
                        <Col xs={12} className="card h-100 d-flex bg-orcaWhite px-5 mb-3" style={{ minHeight: '38vh' }}>
                            <span className="text-center my-auto pt-4">
                                <FontAwesomeIcon icon={faEnvelopeOpenText} className="text-orcaLighter mx-auto h1" />
                                <h4 className="mb-0 mx-auto text-orcaLighter">
                                    {/* mail content preview */}
                                    {t('edm_preview')}
                                </h4>
                            </span>
                            <hr />
                            <small className="mx-auto pb-4 lh-md text-orca text-center w-100">
                                This email was sent to <u>addressee@email.com</u><br />
                                <u>why did I get this?</u>&ensp;
                                <u>unsubscribe from this list</u>&ensp;
                                <u>update subscription preferences</u><br />
                                {/* Sample */}
                                {/* WAVENET • 3F 1 • No. 189 Yan Ping S. Rd. • Zhongzheng Dist. 10065 - Taiwan */}
                                <div className="d-flex flex-wrap w-100">
                                    <div id="mailFooterPreview" className="d-flex mx-auto">
                                        {
                                            Object.keys(footerSetting).map((key, i) => (
                                                <React.Fragment key={i}>
                                                <span
                                                    className="mx-auto"
                                                    style={{ opacity: i === 4 ? '0' : '1' }}
                                                >
                                                        {i % 4 !== 0 && '・'}{i === 4 && '·'}
                                                </span>
                                                <Form.Control
                                                    htmlSize={Math.max(len(footerSetting[key]), len(key))}
                                                    readOnly
                                                    name="key"
                                                    value={footerSetting[key]}
                                                    type="text"
                                                    placeholder={key}
                                                />
                                                </React.Fragment>
                                            ))
                                        }
                                    </div>
                                </div>
                            </small>
                        </Col>
                        <Col xs={6} className="ps-0">
                            {
                                Object.keys(footerSetting).slice(0, 3).map((key, index) => (
                                    <Form.Group key={index} className="mb-3 text-orca" controlId="footer_company">
                                        <Form.Label className="mb-0">
                                            {t(`${key}`)}
                                        </Form.Label>
                                        <Form.Control name={key} value={footerSetting[key]} placeholder={key} onChange={handleFooterSettingChange} />
                                    </Form.Group>
                                ))
                            }
                        </Col>
                        <Col xs={6} className="pe-0">
                            {
                                Object.keys(footerSetting).slice(3, 6).map((key, index) => (
                                    <Form.Group key={index} className="mb-3 text-orca" controlId="footer_company">
                                        <Form.Label className="mb-0">
                                            {t(`${key}`)}
                                        </Form.Label>
                                        <Form.Control name={key} value={footerSetting[key]} placeholder={key} onChange={handleFooterSettingChange} />
                                    </Form.Group>
                                ))
                            }
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Col className="d-flex justify-content-end ps-0">
                        <Button
                            className="my-auto"
                            variant="secondary"
                            name="editFooterSetting"
                            onClick={handleModalClose}
                        >
                            {t('cancel', { ns: 'common' })}
                        </Button>
                        <LoadingButton settings={{
                            "name": modal.action.type,
                            "value": modal.action.targetId,
                            "content": "送出",
                            "loaded": modal.loaded,
                            "onClick": handleModalSubmit
                        }} />
                        {/* <Button
                            className="my-auto ms-2"
                            variant="orca"
                            name="editFooterSetting"
                            onClick={handleModalSubmit}
                        >
                            Submit
                        </Button> */}
                    </Col>
                </Modal.Footer>
            </Modal>
            <FreePaymentModal 
                settings={{
                    content: {
                        source: settings.state.authData.compartment_source,
                    },
                    freePaymentModalShow,
                    handleModalClose,
                }}
            />
            </div>
        </>
    );
}
import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from "react-i18next"
import moment from 'moment'
import { Form, Card, Badge, CloseButton, Button, Modal, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCheck, faCircleCheck, faCircleInfo, faCircleMinus, faCirclePlus, faCircleXmark, faEnvelope, faFlagCheckered, faHourglass, faPlay, faQuestion, faScaleBalanced, faUserGroup, faX } from '@fortawesome/free-solid-svg-icons'
import { PathLine } from 'react-svg-pathline'
import { apiAuthInstance } from '../../apis/backend'
import { v4 as uuidv4 } from 'uuid'

import DndCanvasNodeEditor from './DndCanvasNodeEditor'

// 畫布上的節點卡片
function Content({
    setting
}) {
    const { label, id, onMouseEnter, onMouseLeave, handleDisconnent, onClick, onPlusClick, onBooleanClick, forbidden, type, prev, data } = setting
    const { t, i18n } = useTranslation('DndCanvas', 'WorkflowReportModal', 'common')

    // 顯示節點細節資訊
    const nodeDetail = () => {
        switch (type) {
            case 'entry': {
                return (
                    <></>
                )}
            case 'directories': {
                const color = data.directory.dire_name ? 'green' : 'red'    // green if selected else red
                return (
                    <FontAwesomeIcon icon={data.directory.dire_name ? faCheck : faX} style={{ color }} />
                )}
            case 'smsCampaign': {
                const campaign_name = data.campaign_name
                const dire_name = prev.map((p) => {
                    if (!p.data.campaign_name || !p.prevAction.action) return p.data.directory?.dire_name || ''
                    return p.prevAction
                        ?   p.prevAction.bool
                            ?   p.data.campaign_name + '_' + `${p.prevAction.action}`
                            :   p.data.campaign_name + '_' + `un${p.prevAction.action}`
                        :   p.data.directory.dire_name
                })
                const template_name = data.template.subject
                return (
                    <>
                        <div>{t('campaign_name')}: {campaign_name}</div>
                        <div>{t('directory')}: {dire_name}</div>
                        <div>{t('template')}: {template_name}</div>
                    </>
                )}
            case 'emailCampaign': {
                const campaign_name = data.title
                const dire_name = prev.map((p) => {
                    if (!p.data.campaign_name || !p.prevAction.action) return ''
                    return p.prevAction
                        ?   p.prevAction.bool
                            ?   p.data.campaign_name + '_' + `${p.prevAction.action}`
                            :   p.data.campaign_name + '_' + `un${p.prevAction.action}`
                        :   p.data.directory.dire_name
                })
                const template_name = data.template.template_name
                const sender = data.sender
                return (
                    <>
                        <div>{t('campaign_name')}: {campaign_name}</div>
                        <div>{t('directory')}: {dire_name}</div>
                        <div>{t('template')}: {template_name}</div>
                        <div>{t('sender')}: {sender.sender}</div>
                        <div>{t('reply_to')}: {sender.reply_to}</div>
                    </>
                )}
            case 'booleanDetermination': {
                const action = data.action
                const waitWeeks = data.weeks
                const waitDays = data.days
                const waitHours = data.hours
                return (
                    <div>{t('action')}: {action}</div>
                )}
            case 'wait': {
                return (
                    <></>
                )}
            default:
                break
        }
    }
    const nodeConnection = () => {
        switch (type) {
            case 'entry': {
                return (
                <>
                    <FontAwesomeIcon
                        icon={faCirclePlus}
                        style={{
                            cursor: 'pointer',
                            width: '25px',
                            height: '50px',
                            right: '-15px',
                            top: '25px',
                        }}
                        className="m-auto fs-4 position-absolute text-orca"
                        onClick={onPlusClick}
                        title="新增"
                    />
                </>
            )}
            case 'directories': {
                return (
                    <>
                        <FontAwesomeIcon
                            icon={faCirclePlus}
                            style={{
                                cursor: 'pointer',
                                width: '25px',
                                height: '50px',
                                right: '-15px',
                                top: '25px',
                            }}
                            className="m-auto fs-4 position-absolute text-orca"
                            onClick={onPlusClick}
                            title="新增"
                        />
                        <FontAwesomeIcon
                            icon={forbidden ? faBan : faCircleMinus}
                            style={{
                                cursor: 'pointer',
                                width: '25px',
                                height: '50px',
                                left: '-15px',
                                top: '25px',
                            }}
                            className="m-auto fs-4 position-absolute text-orca"
                            onClick={handleDisconnent}  // 點擊後斷線
                            title="刪除"
                        />  
                    </>
                )
            }
            case 'smsCampaign': {
                return (
                    <>
                        <FontAwesomeIcon
                            icon={forbidden ? faBan : faCircleMinus}
                            style={{
                                cursor: 'pointer',
                                width: '25px',
                                height: '50px',
                                left: '-15px',
                                top: '25px',
                            }}
                            className="m-auto fs-4 position-absolute text-orca"
                            onClick={handleDisconnent}  // 點擊後斷線
                            title="刪除"
                        />  
                        <FontAwesomeIcon
                            icon={faCirclePlus}
                            style={{
                                cursor: 'pointer',
                                width: '25px',
                                height: '50px',
                                right: '-15px',
                                top: '25px',
                            }}
                            className="m-auto fs-4 position-absolute text-orca"
                            onClick={onPlusClick}
                            title="新增"
                        />
                    </>
                )
            }
            case 'emailCampaign': {
                return (
                    <>
                        <FontAwesomeIcon
                            icon={forbidden ? faBan : faCircleMinus}
                            style={{
                                cursor: 'pointer',
                                width: '25px',
                                height: '50px',
                                left: '-15px',
                                top: '25px',
                            }}
                            className="m-auto fs-4 position-absolute text-orca"
                            onClick={handleDisconnent}  // 點擊後斷線
                            title="刪除"
                        />  
                        <FontAwesomeIcon
                            icon={faCirclePlus}
                            style={{
                                cursor: 'pointer',
                                width: '25px',
                                height: '50px',
                                right: '-15px',
                                top: '25px',
                            }}
                            className="m-auto fs-4 position-absolute text-orca"
                            onClick={onPlusClick}
                            title="新增"
                        />
                    </>
                )
            }
            case 'booleanDetermination': {
                return (
                    <>
                        <FontAwesomeIcon
                            icon={forbidden ? faBan : faCircleMinus}
                            style={{
                                cursor: 'pointer',
                                width: '25px',
                                height: '50px',
                                left: '-15px',
                                top: '25px',
                            }}
                            className="m-auto fs-4 position-absolute text-orca"
                            onClick={handleDisconnent}  // 點擊後斷線
                            title="刪除"
                        />  
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            style={{
                                cursor: 'pointer',
                                width: '25px',
                                height: '50px',
                                right: '-15px',
                                top: '5px',
                            }}
                            className="m-auto fs-4 position-absolute text-orca"
                            onClick={() => onBooleanClick(true)}
                            title="新增"
                        />
                        <FontAwesomeIcon
                            icon={faCircleXmark}
                            style={{
                                cursor: 'pointer',
                                width: '25px',
                                height: '50px',
                                right: '-15px',
                                top: '45px',
                            }}
                            className="m-auto fs-4 position-absolute text-orca"
                            onClick={() => onBooleanClick(false)}
                            title="新增"
                        />
                    </>
                )
            }
            case 'wait': {
                return (
                    <>
                        <FontAwesomeIcon
                            icon={forbidden ? faBan : faCircleMinus}
                            style={{
                                cursor: 'pointer',
                                width: '25px',
                                height: '50px',
                                left: '-15px',
                                top: '25px',
                            }}
                            className="m-auto fs-4 position-absolute text-orca"
                            onClick={handleDisconnent}  // 點擊後斷線
                            title="刪除"
                        />  
                        <FontAwesomeIcon
                            icon={faCirclePlus}
                            style={{
                                cursor: 'pointer',
                                width: '25px',
                                height: '50px',
                                right: '-15px',
                                top: '25px',
                            }}
                            className="m-auto fs-4 position-absolute text-orca"
                            onClick={onPlusClick}
                            title="新增"
                        />
                    </>
                )
            }
            default:
                return (
                    <>
                        <FontAwesomeIcon
                            icon={forbidden ? faBan : faCircleMinus}
                            style={{
                                cursor: 'pointer',
                                width: '25px',
                                height: '50px',
                                left: '-15px',
                                top: '25px',
                            }}
                            className="m-auto fs-4 position-absolute text-orca"
                            onClick={handleDisconnent}  // 點擊後斷線
                            title="刪除"
                        />  
                        <FontAwesomeIcon
                            icon={faCirclePlus}
                            style={{
                                cursor: 'pointer',
                                width: '25px',
                                height: '50px',
                                right: '-15px',
                                top: '25px',
                            }}
                            className="m-auto fs-4 position-absolute text-orca"
                            onClick={onPlusClick}
                            title="新增"
                        />
                    </>
                )
        }
    }

    return type === 'entry' ? <div className='d-flex position-relative' style={{
        width: '100px',
        height: '100px',
    }}>
        <div className='d-flex rounded-circle ms-auto my-auto' style={{
            width: '70px',
            height: '70px',
            backgroundColor: 'white',
            // border: '2px solid #42416f'
        }}>
            <FontAwesomeIcon
                icon={faFlagCheckered}
                style={{
                    cursor: 'pointer',
                    width: '35px',
                    height: '35px',
                }}
                className="m-auto fs-4 text-orca"
                onClick={onPlusClick}
                title="啟動／起點"
            />
        </div>
        {nodeDetail()}
        {nodeConnection()}
    </div> : <Card
        className='position-relative DndCard'
        style={{
            width: '200%',
            height: '110%'
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
    >
        <div className='w-100 h-100 d-flex flex-column py-2 px-3' style={{
            overflowY: 'auto'
        }}>
        <Form.Label
            className="align-self-center h-50 px-2 mb-0 rounded boxShadow"
            style={{ pointerEvents: 'none' }}
        >
            {t(`${label}`)}
        </Form.Label>
        {/* 節點細節 */}
        {nodeDetail()}
        </div>
        {/* 節點連接 */}
        {nodeConnection()}
    </Card>
}

function Node({ setting }) {
    const {
        id,
        style,
        content,
        draging,
        setdraging,
        draggable = true,
        setPosition = () => {},
    } = setting
    return (
        <div
        id={id}
        onDragStart={(e) => {
            if (draging !== id) setdraging(id)
            const target = e.target.parentNode.getBoundingClientRect()
            
            const left = e.clientX - target.x
            const top = e.clientY - target.y
            setPosition({
                left,
                top,
            })
        }}
        onDrag={() => {}}
        className="position-absolute d-flex"
        draggable={draggable}
        style={{
            ...style,
            left: style.left - 20 || style.left,
            top: style.top - 20 || style.top,
            cursor: draggable ? 'grab' : 'auto',
            zIndex: 1,
        }}
        >
            {content}
        </div>
    )
}

function DndCanvas(props) {
    const { settings } = props
    const { draggables, setdraggables } = settings
    const { t, i18n } = useTranslation('DndCanvas', 'WorkflowReportModal', 'common')
    const [draging, setdraging] = useState(0)
    const handleDraggableAdd = (node) => {
        const id = uuidv4() // const id = draggables.length ? Math.max(...draggables.map((d) => parseInt(d.id, 10))) + 1 : 1
        setdraggables([
            ...draggables,
            {
                ...node,
                id,
                next: [],
                prev: [],
            },
        ])
    }

    const initNode = {
        id: '',
        type: '',
        data: {
            campaign_name: '',
            title: '',          // email campaign name
            subject_line: '',
            preview_text: '',
            smsTemplate: {
                sms_id: '',
                subject: '',    // sms template name
            },
            emailTemplate: {
                template_id: '',
                template_name: '',
            },
            directory: {
                directory_id: '',
                directory_name: '',
            },
            sendtime: '',
            sender: {
                sender: '',
                replyto: '',
            },
            action: '',         // booleanDetermination action: open, click
            wait: {
                hours: '',
                days: '',
                weeks: '',
            }
        },
    }
    const [node, setNode] = useState(initNode)
    const options = {
        directories: getDirectorires,
        emailDirectories: getEmailDirectorires,
        emailTemplates: getEmailTemplates,
        smsTemplates: getSmsTemplates,
        emailSettingPairs: getEmailSettingPairs,
        workflowDirectories: getWorkflowDirectories
    }
    // console.log(draggables)
    async function getDirectorires() {
        const directories = apiAuthInstance({
            url: 'contact/directory/',
            method: 'get',
            params: {
                channel: 'true',
            }
        })
        return directories
    }

    function getEmailDirectorires() {
        const emailDirectories = apiAuthInstance({
            url: 'contact/directory/',
            method: 'get',
            params: {
                channel: 'email',
            }
        })
        return emailDirectories
    }

    // 修正原本回傳所有Directories，會同時包含 mailchimp 和 sendinblue 群組的問題
    function getWorkflowDirectories() {
        const workflowDirectories = apiAuthInstance({
            url: 'contact/directory/',
            method: 'get',
            params: {
                channel: 'workflow',
            }
        })
        return workflowDirectories
    }

    function getEmailTemplates() {
        const emailTemplates =  apiAuthInstance({
            url: 'email/templates/',
            method: 'get'
        })
        return emailTemplates
    }
    
    function getSmsTemplates() {
        const smsTemplates =  apiAuthInstance({
            url: 'sms/templates/',
            method: 'get'
        })
        return smsTemplates
    }

    async function getEmailSettingPairs() {
        const emailSettingPairs = apiAuthInstance({
            "url": "email/settings/sender/",
            "method": "get",
        })
        return emailSettingPairs
    }

    const lines = useMemo(() => {
        const temp = []
        draggables.forEach(({ style, next }) => {
            if (next) {
                next.forEach ? next.forEach((n) => {
                    const target = draggables.find(({ id }) => id === n)
                    temp.push({
                        start: style,
                        end: target.style
                    })
                }) : Object.keys(next).forEach((key, i) => {
                    const target = draggables.find(({ id }) => id === next[key])
                    if (!target) return
                    temp.push({
                        start: {
                            ...style,
                            top: `${style.top + (i + 1) * 40 - 20}px`
                        },
                        end: target.style
                    })
                })
            }
        })
        return temp
    }, [draggables])

    const [mp, setmp] = useState({
        top: 0,
        left: 0,
    })
    const [drawing, setdrawing] = useState(false)
    const [boolDrawing, setBoolDrawing] = useState(false)
    // const [contextMenu, setcontextMenu] = useState({
    //     show: false,
    //     top: 0,
    //     left: 0,
    // })

    const _getPath = (start, end) => {
        const s = {
            left: parseInt(start.left, 10),
            top: parseInt(start.top, 10),
        }
        const e = {
            left: parseInt(end.left, 10),
            top: parseInt(end.top, 10),
        }
        if (e.left >= s.left + 200) {
            return [
                { x: s.left, y: s.top },
                ...(Math.abs(e.top - s.top) < 50 ? [] : [
                    { x: Math.abs(e.left - s.left) < 40 ? s.left : e.left - 40, y: s.top },
                    { x: Math.abs(e.left - s.left) < 40 ? s.left : e.left - 40, y: e.top },
                ]),
                { x: e.left, y: e.top },
            ]
        } else {
            return [
                { x: s.left, y: s.top },
                { x: s.left, y: e.top > s.top + 190 || (e.top < s.top && e.top > s.top - 380 ) ? e.top - 190 : e.top + 190 },
                { x: Math.abs(e.left - s.left) < 40 ? s.left : e.left - 40, y: e.top > s.top + 190 || (e.top < s.top && e.top > s.top - 380 ) ? e.top - 190 : e.top + 190 },
                { x: Math.abs(e.left - s.left) < 40 ? s.left : e.left - 40, y: e.top },
                { x: e.left, y: e.top },
            ]
        }
    }
    const getPath = (start, end) => {
        const s = {
            left: parseInt(start.left, 10),
            top: parseInt(start.top, 10),
        }
        const e = {
            left: parseInt(end.left, 10),
            top: parseInt(end.top, 10),
        }
        if (e.left >= s.left) {
            return [
                { x: s.left, y: s.top },
                { x: (s.left + e.left) / 2, y: s.top},
                { x: (s.left + e.left) / 2, y: e.top},
                { x: e.left, y: e.top },
            ]
        } else {
            return [
                { x: s.left, y: s.top },
                { x: Math.max(e.left + 220, s.left + 20), y: s.top },
                ...(Math.abs(s.top - e.top) < 70 ? [
                    { x: Math.max(e.left + 220, s.left + 20), y: e.top > s.top ? e.top + 70 : e.top - 70 },
                    { x: e.left - 40, y: e.top > s.top ? e.top + 70 : e.top - 70 },
                ] : [
                    { x: Math.max(e.left + 220, s.left + 20), y: (s.top + e.top) / 2 },
                    { x: e.left - 40, y: (s.top + e.top) / 2 },
                ]),
                { x: e.left - 40, y: e.top },
                { x: e.left, y: e.top },
            ]
        }
    }
    const datetime_now = new Date()
    const initScheduleTime = new Date(datetime_now.setHours(datetime_now.getHours() + parseInt(15 * Math.ceil(datetime_now.getMinutes() /15)/60),(15 * Math.ceil(datetime_now.getMinutes() /15)) % 60, 0))

    const contents = {
        entry: {
            input: 0,
            output: 1,
            data: {},
            label: 'entry',
            icon: <FontAwesomeIcon icon={faPlay} />,
            type: 'entry',
            next: [],
            prev: [],
            disabled: () => 
                draggables.filter((d) => d.type === 'entry').length >= 1,
        },
        directories: {
            data: {
                directory: {
                    dire_name: '',
                    dire_id: '',
                },
            },
            label: 'directories',
            icon: <FontAwesomeIcon icon={faUserGroup} />,
            type: 'directories',
            next: [],
            prev: [],
            forbidden: ['entry, booleanDetermination']
        },
        smsCampaign: {
            data: {
                campaign_name: '',
                template: {
                    subject: '',
                    sms_id: '',
                },
                directory: {
                    dire_name: '',
                    dire_id: '',
                },
                sendtime: '',
                // sendtime: modal.scheduleTime.getFullYear().toString() + (modal.scheduleTime.getMonth() + 1).toString().padStart(2, "0") + modal.scheduleTime.getDate().toString().padStart(2, "0") + modal.scheduleTime.getHours().toString().padStart(2, "0") + modal.scheduleTime.getMinutes().toString().padStart(2, "0") + "00"
                
            },
            label: 'sms_campaign',
            icon: <FontAwesomeIcon icon={faEnvelope} />,
            type: 'smsCampaign',
            next: [],
            prev: [],
            forbidden: ['entry', 'emailCampaign', 'whatsappCampaign', 'lineCampaign'],
        },
        emailCampaign: {
            data: {
                title: '',  // ie campaign_name
                template: {
                    template_name: '',
                    template_id: '',
                },
                directory: {
                    dire_name: '',
                    dire_id: '',
                },
                sender: {
                    sender: '',
                    reply_to: '',
                },
                sendtime: '',
                // schedule_time: modal.scheduleTime.getFullYear().toString() +"-"+ (modal.scheduleTime.getMonth() + 1).toString().padStart(2, "0") +"-"+  modal.scheduleTime.getDate().toString().padStart(2, "0") + " " + modal.scheduleTime.getHours().toString().padStart(2, "0") + ":" + modal.scheduleTime.getMinutes().toString().padStart(2, "0") + ":00",
            },
            label: 'email_campaign',
            icon: <FontAwesomeIcon icon={faEnvelope} />,
            type: 'emailCampaign',
            next: [],
            prev: [],
            forbidden: ['entry', 'smsCampaign', 'whatsappCampaign', 'lineCampaign'],
        },
        booleanDetermination: {
            data: {
                action: '', // open, click
                wait: {
                    hours: 0,
                    days: 0,
                    weeks: 0,
                }
            },
            label: 'boolean_determination',
            icon: <FontAwesomeIcon icon={faQuestion} />,
            type: 'booleanDetermination',
            next: {
                true: '',
                false: '',
            },
            prev: [],
            forbidden: ['entry', 'booleanDetermination', 'directories', 'whatsappCampaign', 'lineCampaign', 'wait'],
            inherit: true,
        },
        // wait: {
        //     data: {
        //         wait: {
        //             hours: 0,
        //             days: 0,
        //             weeks: 0,
        //         }
        //     },
        //     label: 'wait',
        //     icon: <FontAwesomeIcon icon={faHourglass} />,
        //     type: 'wait',
        //     next: [],
        //     prev: [],
        //     forbidden: ['wait', 'directories', 'booleanDetermination']
        // },
    }
    const [selectedId, setselectedId] = useState(false)
    const [forbidden, setforbidden] = useState(false)
    const selected = useMemo(() => draggables.find((d) => d.id === selectedId), [selectedId, draggables])
    const handleDisconnent = (end) => {
        const start = draggables.find((dr) => dr.next.true === end || dr.next.false === end || (dr.next.includes && dr.next.includes(end)))
        if (!start) {
            console.log('impossible')
        }
        else{
        if (start.inherit) {
            setBoolDrawing({
                ...start,
                bool: start.next.true === end,
            }) 
        } else {
            setdrawing(start)
        }
        setdraggables(draggables.map((d) => {
            if (d.id === start.id) {
                return {
                    ...d,
                    next: Array.isArray(d.next) ? d.next.filter((dn) => dn !== end) : {
                        true: d.next.true === end ? '' : d.next.true,
                        false: d.next.false === end ? '' : d.next.false,
                    }  
                }
            }
            if (d.id === end) {
                return {
                    ...d,
                    prev: d.prev.filter((dp) => dp !== start.id)
                }
            }
            return d
        }))
        }
    }
    const handleDeleteNode = (id) => {
        setdraggables(draggables.map((dr) => ({
            ...dr,
            next: dr.next.filter ? dr.next.filter((drn) => drn !== id) : Object.keys(dr.next).reduce((prev, cur) => ({
                ...prev,
                [cur]: dr.next[cur] === id ? '' : dr.next[cur] 
            }), {}),
            prev: dr.prev.filter((drp) => drp !== id),
        })).filter((dr) => dr.id !== id))
    }
    const handleCloseNodeEditing = () => {
        setselectedId(false)
    }

    const [position, setPosition] = useState({
        left: 0,
        top: 0
    })

    return (
        // <div
        //     className="py-3 px-4 w-100 h-100"
        //     style={{
        //         minHeight: '100%',
        //         // backgroundColor: '#ddd',
        //     }}
        // >
            <Row
                className="position-relative w-100 h-100 d-flex mt-3 ms-2"
                style={{ overflowY: 'auto', overflowX: 'hidden', backgroundColor: '#ddd',  }}
            >
                <Col
                    id="mousemoving"
                    xs={10}
                    className="border-none rounded"
                    style={{
                        // backgroundColor: '#ddd',
                        minHeight: '200%',
                        maxHeight: 'auto',
                    }}
                    fluid
                    onMouseMove={(e) => {
                        if (e.target.id === 'mousemoving') {
                            setmp({
                                top: e.nativeEvent.offsetY,
                                left: e.nativeEvent.offsetX,
                            })
                        }
                    }}
                    onContextMenu={(e) => {
                        e.preventDefault()
                        // setcontextMenu({
                        //     show: true,
                        //     top: e.nativeEvent.offsetY,
                        //     left: e.nativeEvent.offsetX,
                        // })
                    }}
                    onClick={(e) => {
                        if (drawing) setdrawing(0)
                        if (boolDrawing) setBoolDrawing(0)
                        // if (contextMenu.show) {
                        //     setcontextMenu({
                        //         ...contextMenu,
                        //         show: false,
                        //     })
                        // }
                    }}
                    onDrop={(e) => {
                        const target = e.currentTarget.getBoundingClientRect()
                        const left = e.clientX - target.x
                        const top = e.clientY - target.y
                        
                        if (contents[draging]) {
                            handleDraggableAdd({
                                ...contents[draging],
                                style: {
                                    left,
                                    top,
                                }
                            })
                        } else {
                            // console.log(draging)
                            setdraggables(
                                draggables.map((d) => 
                                    d.id === draging
                                    ? { ...d, style: { ...d.style, top: d.style.top + top - position.top, left: d.style.left + left - position.left } }
                                    : d
                                )
                            )
                        }
                    }}
                    onDragOver={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                    }}
                >
                    {draggables.map((d) => (
                        <Node
                            key={d.id}
                            setting={{
                            ...d,
                            setPosition,
                            style: {
                                ...d.style,
                                width: '200px',
                                minWidth: '200px',
                                maxWidth: '200px',
                                height: '100px',
                                cursor: forbidden ? 'not-allowed' : 'auto',
                            },
                            content:
                                <Content setting={{
                                    id: d.id,
                                    label: d.label,
                                    onMouseEnter: (e) => {
                                        if (drawing) {
                                            if ((d.forbidden && d.forbidden.includes(drawing.type)) || d.prev.length || d.prev.true || d.prev.false) {
                                                setforbidden(true)
                                            } else {
                                                setmp({
                                                    left: d.style.left,
                                                    top: parseInt(d.style.top, 10) + 40,
                                                })
                                            }
                                        }
                                        if (boolDrawing) {
                                            if ((d.forbidden && d.forbidden.includes(boolDrawing.type)) || d.prev.length || d.prev.true || d.prev.false) {
                                                setforbidden(true)
                                            } else {
                                                setmp({
                                                    left: d.style.left,
                                                    top: parseInt(d.style.top, 10) + 40,
                                                })
                                            }
                                        }
                                        e.stopPropagation()
                                    },
                                    onMouseLeave: () => setforbidden(false),
                                    onClick: () => {
                                        if (drawing) {
                                            if ((d.forbidden && d.forbidden.includes(drawing.type)) || d.prev.length || d.prev.true || d.prev.false) {
                                                setforbidden(true)
                                            } else {
                                                const from = draggables.find(({ id }) => id === drawing.id)
                                                if (!from.next.includes(d.id)) {
                                                    setdraggables(draggables.map((dr) => dr.id === drawing.id ? {
                                                        ...dr,
                                                        next: [...dr.next, d.id],
                                                    } : dr.id === d.id ? {
                                                        ...d,
                                                        prev: [...d.prev, from.id]
                                                    } : dr))
                                                }
                                            }
                                            setdrawing(0)
                                        } else if (boolDrawing) {
                                            if ((d.forbidden && d.forbidden.includes(boolDrawing.type)) || d.prev.length || d.prev.true || d.prev.false) {
                                                setforbidden(true)
                                            } else {
                                                const from = draggables.find(({ id }) => id === boolDrawing.id)
                                                setdraggables(draggables.map((dr) => dr.id === boolDrawing.id ? {
                                                    ...dr,
                                                    next: {
                                                        ...dr.next,
                                                        [boolDrawing.bool]: d.id
                                                    },
                                                } : dr.id === d.id ? {
                                                    ...d,
                                                    prev: [...d.prev, from.id]
                                                } : dr))
                                            }
                                            setBoolDrawing(0)
                                        } else {
                                            setselectedId(d.id)
                                        }
                                    },
                                    onPlusClick: () => {
                                        if (d.next.length) {
                                            handleDisconnent(d.next[0])
                                        } else {
                                            setdrawing(d)
                                        }
                                    },
                                    onBooleanClick: (bool) => {
                                        if (d.next[bool]) {
                                            handleDisconnent(d.next[bool])
                                        } else {
                                            setBoolDrawing({
                                                ...d,
                                                bool,
                                            })
                                        }
                                    },
                                    handleDisconnent: () => handleDisconnent(d.id),
                                    forbidden,
                                    type: d.type,
                                    prev: d.prev.reduce((pre, cur) => {
                                        const target = draggables.find((dr) => dr.id === cur)
                                        return target.inherit ? [
                                            ...pre,
                                            ...target.prev.map((tid) => ({
                                                ...draggables.find((dr) => dr.id === tid),
                                                prevAction: {
                                                    ...target.data,
                                                    bool: d.id === target.next.false
                                                }
                                            }))
                                        ] : [
                                            ...pre,
                                            target,
                                        ]
                                    }, []),
                                    data: d.data,
                                }} />,
                                draging,
                                setdraging,
                            }}
                        />
                    ))}
                </Col>
                {/* 點擊畫布節點後 右側出現的節點細節選項 or 節點選單 */}
                <Col
                    xs={2}
                    className='d-flex flex-column'
                    style={{
                        backgroundColor: 'white',
                        minHeight: '60%',
                        maxHeight: '60vh',
                        position: 'fixed',
                        right: '0.5%',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    <h5 className="fw-bolder text-orca mt-1 mb-2 text-center">
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            title="Click & Drag to add"
                            style={{ cursor: 'help' }}
                        />
                        &ensp;{t('tool_list')}
                    </h5>
                    {selected ? <Card className='h-100'>
                            <Card.Header className='d-flex'>
                                {t(selected.label)}
                                <CloseButton className='ms-auto' onClick={() => setselectedId(false)} />
                            </Card.Header>
                            {/* Card.Body中放置卡片資訊細節 TODO:另外寫Component */}
                            {/* <Card.Body>{selected.label}</Card.Body>  */}
                            <Card.Body style={{
                                height: '90%'
                            }}>
                                <DndCanvasNodeEditor settings={{
                                    node: selected,
                                    // parents: draggables.filter((d) => d.next && d.next.includes(selected.id)),
                                    draggables,
                                    options,
                                    setNode: (values) => {
                                        setdraggables((prevState) => prevState.map((p) => p.id === values.id ? values : p))
                                    },
                                    prev: selected.prev.reduce((pre, cur) => {
                                        const target = draggables.find((dr) => dr.id === cur)
                                        return target.inherit ? [
                                            ...pre,
                                            ...target.prev.map((tid) => ({
                                                ...draggables.find((dr) => dr.id === tid),
                                                prevAction: {
                                                    ...target.data,
                                                    bool: selected.id === target.next.true
                                                }
                                            }))
                                        ] : [
                                            ...pre,
                                            target,
                                        ]
                                    }, []),
                                    handleDeleteNode,
                                    // dateTimePickerModalParams: {
                                    //     scheduleModalShow: scheduleModal.scheduleModalShow,
                                    //     time: scheduleModal.scheduleTime,
                                    //     onTimeChange: onModalChange,
                                    //     onCloseClick: handleModalClose,
                                    //     onSubmitClick: handleScheduleModalSubmit,
                                    //     handleScheduleModalShow,
                                    // }
                                    handleCloseNodeEditing,
                                }}
                                />
                            </Card.Body> 
                        </Card> : Object.keys(contents).map((key) =>
                        <Badge
                            className='py-3 m-2'
                            title="按住以新增或拖曳"
                            bg={!contents[key].disabled || !contents[key].disabled() ? 'orcaMid' : 'secondary'}
                            onDragStart={() => setdraging(contents[key].type)}
                            onDrag={() => {}}
                            style={{
                                zIndex: 1,
                                cursor: 'grab'
                            }}
                            draggable={!contents[key].disabled || !contents[key].disabled()}
                        >
                            {contents[key].icon}&ensp;&ensp;&ensp;{t(contents[key].label)}
                        </Badge>
                    )
                    }
                </Col>
                {/* <Dropdown.Menu className='position-absolute' style={{
                    left: contextMenu.left,
                    top: contextMenu.top,
                }} show={contextMenu.show}>
                    <option onClick={() => {
                        handleDraggableAdd()
                        setcontextMenu({
                            ...contextMenu,
                            show: false,
                        })
                    }} style={{
                        cursor: 'pointer',
                    }}>
                        Add Node
                    </option>
                </Dropdown.Menu> */}
                {lines.map(({ start, end }, index) => <svg className='position-absolute' stroke="#9E9DD3" strokeWidth={5} strokeDashoffset={1} style={{
                    top: 0,
                    left: 0,
                    height: '200%',
                    width: '100%',
                    pointerEvents: 'none',
                    // strokeDashoffset: "1",
                }}>
                    <PathLine 
                        points={getPath({ left: start.left + (index ? 170 : 90), top: start.top + 32 }, { left: end.left, top: end.top + 32 })} 
                        stroke="#9E9DD3"
                        strokeWidth="5"
                        stroke-dasharray="5"
                        fill="none"
                        r={1}
                        />
                </svg>)}
                {drawing ? <svg className='position-absolute' style={{
                    top: 0,
                    left: 0,
                    height: '200%',
                    width: '100%',
                    pointerEvents: 'none',
                    stroke: "#9E9DD3",
                    strokeWidth: "5",
                    strokeDashoffset: "1",
                }}>
                    <PathLine 
                        points={getPath({ left: drawing.style.left + (drawing.type !== 'entry' ? 170 : 90), top: drawing.style.top + 32 }, { left: mp.left, top: mp.top })} 
                        stroke="#9E9DD3"
                        strokeWidth="5"
                        stroke-dasharray="5"
                        fill="none"
                        r={1}
                        />
                </svg> : <div className="d-none"  /> }
                {boolDrawing ? <svg className='position-absolute' style={{
                    top: 0,
                    left: 0,
                    height: '200%',
                    width: '100%',
                    pointerEvents: 'none',
                    stroke: "#9E9DD3",
                    strokeWidth: "5",
                    strokeDashoffset: "1",
                }}>
                    <PathLine 
                        points={getPath({ left: boolDrawing.style.left + 170, top: boolDrawing.style.top + (boolDrawing.bool ? 20 : 44) }, { left: mp.left, top: mp.top })} 
                        stroke="#9E9DD3"
                        strokeWidth="5"
                        stroke-dasharray="5"
                        fill="none"
                        r={1}
                        />
                </svg> : <div className="d-none" /> }
            </Row>
        // </div>
    )
}

// DndCanvas.propTypes = {
//   auth: PropTypes.shape().isRequired,
//   handleLoginShow: PropTypes.func.isRequired,
// }

export default DndCanvas

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select'
import { purpleSelector } from '../../components/shared/ReactSelectData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { apiAuthInstance } from '../../apis/backend';
import TableList from '../../components/shared/TableList';
import LoadingButton from '../../components/shared/LoadingButton';
import ModalApiStatus from "../../components/modal/ModalApiStatus"
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"

export default function UserAccountManagement(props) {
    const { settings } = props
    const authRank = settings.state.authData.auth_rank

    const { t, i18n } = useTranslation('UserAccountManagement', 'common')

    const initTableListData = {
        "counts": 0,
        "data": []
    }
    const [tableListData, setTableListData] = useState(initTableListData);

    const initModalData = {
        "comp_id": "",
        "email": ""
    }
    const initModalAction = {
        "type": "",
        "targetId": undefined
    };

    const initModal = {
        data: initModalData,
        compList: [],
        action: initModalAction,
        loaded: true,
        show: false,
        warningModalShow: false,
        activeUserModalShow: false,
    }
    const [modal, setModal] = useState(initModal);
    const initAcitveUserData = {
        comp_id: '',
        email: '',
        password: '',
    }
    const [activeUserData, setActiveUserData] = useState(initAcitveUserData)
    const [compList, setCompList] = useState([])
    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)

    const handleModalClose = () => {
        setModal(initModal)
        setApiStatus(initApiStatus)
        setActiveUserData(initAcitveUserData)
    }
    const handleModalShow = (event) => {
        switch (event.target.name) {
            case "create":
                getCompList()
                setModal(prevState => ({
                    ...prevState,
                    data: initModalData,
                    action: {
                        ...initModalAction,
                        type: "create"
                    },
                    show: true,
                    loaded: true
                }))
                break
            case "createActiveUser":
                getCompList()
                setModal(prevState => ({
                    ...prevState,
                    data: initModalData,
                    action: {
                        ...initModalAction,
                        type: "createActiveUser"
                    },
                    show: false,
                    activeUserModalShow: true,
                    loaded: true
                }))
                break
            case "resend":
                setModal(prevState => ({
                    ...prevState,
                    data: initModalData,
                    action: {
                        type: "resend",
                        targetId: event.target.value,
                    },
                    show: false,
                    warningModalShow: true
                }))
                break
            case "delete":
                setModal(prevState => ({
                    ...prevState,
                    data: initModalData,
                    action: {
                        type: "delete",
                        targetId: event.target.value,
                    },
                    show: false,
                    warningModalShow: true
                }))
                break

            default:
                break
        }
    }
    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    warningModalShow: true
                }))
                break;
            case "createActiveUser":
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    activeUserModalShow: false,
                    warningModalShow: true
                }))
                break
            case "resend":
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    warningModalShow: true
                }))
                break;
            case "delete":
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    warningModalShow: true
                }))
                break;
            default:
                break;
        }

    }
    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case 'createActiveUser':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    activeUserModalShow: true,
                    warningModalShow: false
                }))
                break
            default:
                setModal(initModal)
                break
        }
    }
    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case 'create':
                postUserData();
                break
            case 'createActiveUser':
                postActiveUserData()
                break
            case 'resend':
                resendUserAuthEmail(modal.action.targetId)
                break
            case 'delete':
                deleteUser(modal.action.targetId)
                break
            default:
                //pass
                break
        }
    }
    const onModalChange = (event, action) => {
        if (action !== undefined && action.name === "compartment-options") {
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...prevState.data,
                    "comp_id": event.id
                }
            }));
        } else {
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...prevState.data,
                    [event.target.name]: event.target.value
                }
            }));
        }
    }

    async function getCompList() {
        apiAuthInstance({
            "url": "account/compartment-admin/",
            "method": "get",
            "params": {
                "comp_only": "true"
            }
        }).then((response) => {
            setCompList(response.data.data)
            setModal(prevState => ({
                ...prevState,
                "compList": response.data.data
            }));
        })
    }
    async function getUserList() {
        await apiAuthInstance({
            url: "account/user-detail-admin/",
            method: "get",
        }).then((response) => {
            setTableListData(response.data);
        });
    }
    async function postActiveUserData() {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: 'account/user/active/',
            method: 'post',
            data: {
                comp_id: activeUserData.comp_id,
                email: activeUserData.email,
                password: activeUserData.password,
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false,
                activeUserModalShow: false,
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            setActiveUserData(initAcitveUserData)
            getUserList()
        }).catch((error) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false,
                activeUserModalShow: false,
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function postUserData() {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: "account/user-detail-admin/",
            method: "post",
            data: modal.data
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false,
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getUserList()
        }).catch((error) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function resendUserAuthEmail(email) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }));
        await apiAuthInstance({
            url: "account/user-detail-admin/",
            method: "post",
            data: {
                comp_id: "",
                email: email,
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false,
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getUserList()
        }).catch((error) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function deleteUser(email) {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            url: "account/user-detail-admin/",
            method: "delete",
            data: {
                email: email,
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false,
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getUserList()
        }).catch((error) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    useEffect(() => {
        // getCompList()
        // getUserList
        apiAuthInstance({
            "url": "account/user-detail-admin",
            "method": "get",
        }).then((response) => {
            setTableListData(response.data);
        });
    }, []);
    return (
        <div className="Table-DefaultHeight Table-AutoWidth">
            <Row xs="auto" className="mb-2">
                <div className="d-flex justify-content-between w-100">
                    <Button variant="orcaLight" name="create" onClick={handleModalShow} title="新增">
                        <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: 'none' }} className="pe-2" />
                        {t('create_user_account')}
                    </Button>
                    <Button variant="orcaLight" name="createActiveUser" onClick={handleModalShow} title="直接新增">
                        <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: 'none' }} className="pe-2" />
                        {t('新增毋需驗證的使用者')}
                    </Button>
                </div>
            </Row>
            {/* 新增會寄發驗證信的使用者 */}
            <Modal show={modal.show} size="lg">
                <Modal.Header closeButton onHide={handleModalClose}>
                    <Modal.Title>{t('create_user_account')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="px-4 py-4">
                        <Row className="h-100 w-100 m-0">
                            <Col xs={4} className="d-flex text-orcaLighter">
                                <FontAwesomeIcon className="m-auto" style={{ height: '7rem' }} icon={faUserPlus} />
                            </Col>
                            <Col xs={8}>
                                <Form.Group className="mb-3" controlId="formBasicEmail" >
                                    <Form.Label>{t(`email`, { ns: 'common'})}</Form.Label>
                                    <Form.Control name="email" type="email" placeholder="Type email of new user..." defaultValue={modal.data.email} onChange={onModalChange}/>
                                </Form.Group>
                                {authRank === "developer" && 
                                    <Form.Group className="mb-3" controlId="formBasicPhone">
                                        <Form.Label>{t(`compartment`, { ns: 'common'})}</Form.Label>
                                        <Select
                                            styles={purpleSelector}
                                            name="compartment-options"
                                            options={compList.map((item) => ({ "value": item.comp_name, "label": item.corp_name + "/" + item.comp_name, "id": item.comp_id }))}
                                            onChange={onModalChange}
                                        />
                                    </Form.Group>
                                }

                            </Col>
                        </Row>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "Submit",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>

            {/* 新增使用者，直接開通不會寄發通知信 */}
            <Modal show={modal.activeUserModalShow} size="lg">
                <Modal.Header closeButton onHide={handleModalClose}>
                    <Modal.Title>{t('新增使用者，直接開通不會寄發通知信')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="px-4 py-4">
                        <Row className="h-100 w-100 m-0">
                            <Col xs={4} className="d-flex text-orcaLighter">
                                <FontAwesomeIcon className="m-auto" style={{ height: '7rem' }} icon={faUserPlus} />
                            </Col>
                            <Col xs={8}>
                                <Form.Group className="mb-3" controlId="formBasicEmail" >
                                    <Form.Label>{t(`email`, { ns: 'common'})}</Form.Label>
                                    <Form.Control 
                                        name="email" 
                                        type="email" 
                                        placeholder="Type email of new user..." 
                                        defaultValue={activeUserData.email} 
                                        onChange={(e) => {setActiveUserData({
                                            ...activeUserData,
                                            email: e.target.value,
                                        })}}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword" >
                                    <Form.Label>{t(`password`, { ns: 'common'})}</Form.Label>
                                    <Form.Control 
                                        name="password" 
                                        type="password" 
                                        placeholder="Type password of new user..." 
                                        defaultValue={activeUserData.password} 
                                        onChange={(e) => {setActiveUserData({
                                            ...activeUserData,
                                            password: e.target.value,
                                        })}}
                                    />
                                </Form.Group>
                                {authRank === "developer" && 
                                    <Form.Group className="mb-3" controlId="formBasicPhone">
                                        <Form.Label>{t(`compartment`, { ns: 'common'})}</Form.Label>
                                        <Select
                                            styles={purpleSelector}
                                            name="compartment-options"
                                            options={compList.map((item) => ({ "value": item.comp_name, "label": item.corp_name + "/" + item.comp_name, "id": item.comp_id }))}
                                            onChange={(e) => {setActiveUserData({
                                                ...activeUserData,
                                                comp_id: e.id,
                                            })}}
                                        />
                                    </Form.Group>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "Submit",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>
            <TableList settings={{
                tableConfig: [
                    {
                        "head": "compartment",
                        "dataType": "text-center",
                        "fieldName": "comp_name"
                    },
                    {
                        "head": "email",
                        "dataType": "text-center",
                        "fieldName": "email"
                    },
                    {
                        "head": "permission",
                        "dataType": "text-center",
                        "fieldName": "perm_name"
                    },
                    {
                        "head": "orca",
                        "dataType": "boolean",
                        "fieldName": "orca_usage"
                    },
                    {
                        "head": "active",
                        "dataType": "boolean",
                        "fieldName": "is_active"
                    },
                    {
                        "head": "staff",
                        "dataType": "boolean",
                        "fieldName": "staff"
                    },
                    {
                        "head": "admin",
                        "dataType": "boolean",
                        "fieldName": "admin"
                    },
                    {
                        "head": "functions",
                        "dataType": "resend and delete",
                        "fieldAsValue": "email",
                        "onResendClick": handleModalShow,
                        "onDeleteClick": handleModalShow,
                    }
                ],
                "data": tableListData.data,
                "size": 10,
                "pagination": true,
            }}
            />
            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                }}
            />
            <ModalSubmitWarning settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "warningModalShow": modal.warningModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleWarningModalSubmit,
                "handleModalClose": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />
        </div>

    );
}
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import Select from 'react-select';
import { apiAuthInstance } from '../../apis/backend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faUserGear } from '@fortawesome/free-solid-svg-icons';
import TableList from '../../components/shared/TableList';
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning";
// import { IconTitledOption, IconTitledControl, purpleSelector } from '../../components/shared/ReactSelectData'
import LoadingButton from '../../components/shared/LoadingButton'

export default function ShortLinkSetting() {
    const { t, i18n } = useTranslation('ShortLinkSetting', 'common')
    const initModalData = {
        "title": "",
        "long_url": "",
        "short_url": "",
        "alias": "",
        "source": "",
        "medium": "",
        "campaign": "",
    }
    const initShorturlListsData = {
        "title": "",
        "long_url": "",
        "short_url": "",
        "alias": "",
        "source": "",
        "medium": "",
        "campaign": "",
    }
    const initModalAction = {
        "type": "",
        "targetId": undefined
    }
    const initModal = {
        "data": initModalData,
        "action": initModalAction,
        "loaded": true,
        "show": false,
        "warningModalShow": false,
    }
    const [shorturlLists, setShorturlLists] = useState([])
    const [shorturlListsData, setShorturlListsData] = useState(initShorturlListsData);
    const [modal, setModal] = useState(initModal);

    const handleModalShow = (event) => {
        let selectedShorturlListsData = undefined
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "create"
                    },
                    "show": true,
                    "warningModalShow": false,
                }));
                break;

            case "edit":
                selectedShorturlListsData = shorturlLists.find(shorturlLists => shorturlLists.id === parseInt(event.target.value));
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "type": "edit",
                        "targetId": event.target.value
                    },
                    "show": true,
                    "warningModalShow": false
                }));
                setShorturlListsData({
                    "id": selectedShorturlListsData.id,
                    "title": selectedShorturlListsData.title,
                    "long_url": selectedShorturlListsData.long_url,
                    "alias": selectedShorturlListsData.alias,
                    "source": selectedShorturlListsData.source,
                    "medium": selectedShorturlListsData.medium,
                    "campaign": selectedShorturlListsData.campaign,
                })
                break;

            case "delete":
                selectedShorturlListsData = shorturlLists.find(shorturlLists => shorturlLists.id === parseInt(event.target.value));
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...initModalData,
                        "id": selectedShorturlListsData.id
                    },
                    "action": {
                        "type": "delete",
                        "targetId": event.target.value
                    },
                    "show": false,
                    "warningModalShow": true
                }));
                break;
    
            default:
                //pass
                break;
        }
    }

    const handleModalClose = () => {
        setModal(prevState => ({
            ...prevState,
            "data": initModalData,
            "action": initModalAction,
            "show": false,
            "warningModalShow": false,
        }));
        setShorturlListsData(initShorturlListsData);
    }

    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                }));
                break;

            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                }));
                break;

            default:
                //pass
                break;
        }
    }
    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false,
                }));
                break;
            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false,
                }));
                break;
            case "delete":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                }));
                break;
            default:
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                }));
                break;
        }
    }

    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                createShortLink()
                break
            case "edit":
                putShortLink()
                break
            case "delete":
                deleteShortLink()
                break

            default:
                //pass
                break;
        }
    }

    async function getShorturlLists() {
        await apiAuthInstance({
            "url": "shorturl/alias/",
            "method": "get"
        }).then((response) => {
            setShorturlLists(response.data.data);
        });
    }

    async function createShortLink() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "shorturl/alias/",
            "method": "post",
            "data":{
                "title": shorturlListsData.title,
                "long_url": shorturlListsData.long_url,
                "source":shorturlListsData.source,
                "medium":shorturlListsData.medium,
                "campaign":shorturlListsData.campaign,
            }
        }).then((response) => {
            getShorturlLists()
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "efficiencyModal": false
            }));
            setShorturlListsData(shorturlListsData);
        });
    }

    async function putShortLink() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "shorturl/alias/" + modal.action.targetId + "/",
            "method": "put",
            "data":{
                "title": shorturlListsData.title,
                "long_url": shorturlListsData.long_url,
                "source":shorturlListsData.source,
                "medium":shorturlListsData.medium,
                "campaign":shorturlListsData.campaign,
            }
        }).then((response) => {
            getShorturlLists()
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "efficiencyModal": false
            }));
            setShorturlListsData(initShorturlListsData);
        });
    }

    async function deleteShortLink() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "shorturl/alias/" + modal.action.targetId + "/",
            "method": "delete",
        }).then((response) => {
            getShorturlLists()
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "efficiencyModal": false
            }));
        });
    }

    useEffect(() => {
        getShorturlLists()
    }, []);

    return (
        <>
        <div className="d-flex flex-column h-100">
            <Row xs="auto" className="mb-2 mailSendingTabs">
                <div className="d-flex justify-content-between w-100">
                    <Button variant="orcaLight" name="create" onClick={handleModalShow}>
                        <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                        {t('create_short_url')}
                    </Button>
                </div>  
            </Row>
            <ModalSubmitWarning settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "warningModalShow": modal.warningModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleWarningModalSubmit,
                "handleModalClose": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />
            <TableList settings={{
                tableConfig: [
                    {
                        "head": "name",
                        "dataType": "text-center",
                        "fieldName": "title"
                    },
                    {
                        "head": "long_url",
                        "dataType": "text",
                        "fieldName": "long_url"
                    },
                    {
                        "head": "short_url",
                        "dataType": "text",
                        "fieldName": "short_url"
                    },
                    {
                        "head": "utm_source",
                        "dataType": "text-center",
                        "fieldName": "source"
                    },
                    {
                        "head": "utm_medium",
                        "dataType": "text-center",
                        "fieldName": "medium"
                    },
                    {
                        "head": "utm_campaign",
                        "dataType": "text-center",
                        "fieldName": "campaign"
                    },
                    {
                        "head": "functions",
                        "dataType": "update and copy and delete",
                        "fieldAsValue": "id",
                        "onUpdateClick": handleModalShow,
                        "onDeleteClick": handleModalShow
                    },
                ],
                "data": shorturlLists,
                "size": 10,
                "pagination": true,
            }}
            />

            <Modal show={modal.show} size="lg">
                <Modal.Header closeButton onHide={handleModalClose}>
                    { modal.action.type === 'create' && <Modal.Title>{t('create_short_url')}</Modal.Title> }
                    { modal.action.type === 'edit' && <Modal.Title>{t('edit_short_url')}</Modal.Title> }
                    {/* <Modal.Title>{modal.action.type} the new short link</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="d-flex px-5">
                    <Row className="w-100 h-100 m-0">
                        <Col xs={5} className="d-flex text-orcaLighter">
                            <FontAwesomeIcon className="m-auto" style={{ height: '7rem' }} icon={faUserGear} />
                        </Col>
                        <Col xs={7} className="pt-2 d-flex flex-column">
                            <Form.Group className="mb-2 text-orca" controlId="formBasicTitle">
                                <Form.Label>{t('short_url_name')}</Form.Label>
                                <Form.Control maxLength="40" name="shorturlTitle" type="text" defaultValue={shorturlListsData.title} onChange={(event) => setShorturlListsData({ ...shorturlListsData, 'title': event.target.value })} />
                            </Form.Group>
                            <Form.Group className="mb-2 text-orca oneLineLimit" controlId="formBasicFromName">
                                <Form.Label>{t('long_url')}</Form.Label>
                                <Form.Control name="shorturlLongUrl" type="url" defaultValue={shorturlListsData.long_url} onChange={(event) => setShorturlListsData({ ...shorturlListsData, 'long_url': event.target.value })} />
                            </Form.Group>

                            <Form.Group className="mb-2 text-orca" controlId="formBasicFromName">
                                <Form.Label>{t('UTM Source')}</Form.Label>
                                <Form.Control disabled={modal.action.type === 'edit' ? true : false} maxLength="40" name="shorturlSource" type="text" defaultValue={shorturlListsData.source} onChange={(event) => setShorturlListsData({ ...shorturlListsData, 'source': event.target.value })} />
                            </Form.Group>
                            <Form.Group className="mb-2 text-orca" controlId="formBasicFromName">
                                <Form.Label>{t('UTM Medium')}</Form.Label>
                                <Form.Control disabled={modal.action.type === 'edit' ? true : false} maxLength="40" name="shorturlMedium" type="text" defaultValue={shorturlListsData.medium} onChange={(event) => setShorturlListsData({ ...shorturlListsData, 'medium': event.target.value })} />
                            </Form.Group>
                            <Form.Group className="mb-2 text-orca" controlId="formBasicFromName">
                                <Form.Label>{t('UTM Campaign')}</Form.Label>
                                <Form.Control disabled={modal.action.type === 'edit' ? true : false} maxLength="40" name="shorturlCampaign" type="text" defaultValue={shorturlListsData.campaign} onChange={(event) => setShorturlListsData({ ...shorturlListsData, 'campaign': event.target.value })} />
                            </Form.Group>


                            {/* <Form.Group className="w-100 mb-2" controlId="formBasicReplyTo">
                                <Form.Label>Short Url</Form.Label>
                                <Form.Control name="shorturlShortUrl" type="url" value={shorturlListsData.short_url} onChange={(event) => setShorturlListsData({ ...shorturlListsData, 'short_url': event.target.value })} />
                            </Form.Group> */}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common' })}
                    </Button>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "送出",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>
        </div>
        </>
    );
}
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const DOMAIN = process.env.REACT_APP_DOMAIN;

const BACKEND_URL = ENVIRONMENT === "local" ? "http://localhost:8000" : "";
const WEBSOCKET_DOMAIN = ENVIRONMENT === 'local' ? "ws://127.0.0.1:8000" : `wss://${DOMAIN}`;
const FACEBOOK_DOMAIN = ENVIRONMENT === 'local' ? `http://${DOMAIN}:8000` : `https://${DOMAIN}`;

export { 
  ENVIRONMENT, 
  BACKEND_URL, 
  WEBSOCKET_DOMAIN, 
  FACEBOOK_DOMAIN
};

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select'
import { purpleSelector } from '../../components/shared/ReactSelectData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUserPlus, faArrowDownWideShort } from '@fortawesome/free-solid-svg-icons'
import { apiAuthInstance } from '../../apis/backend';
import TableList from '../../components/shared/TableList';
import LoadingButton from '../../components/shared/LoadingButton';
import { Dropdown } from "react-bootstrap";

export default function UserAccountManagement(props) {
    const { settings } = props
    const authRank = settings.state.authData.auth_rank

    const { t, i18n } = useTranslation('UserAccountManagement', 'common')

    const initTableListData = {
        "counts": 0,
        "data": []
    }
    const [tableListData, setTableListData] = useState(initTableListData);

    const initModalData = {
        "comp_id": "",
        "email": ""
    }
    const initModalAction = {
        "type": "",
        "targetId": undefined
    };
    const initModalShow = false;

    const initModal = {
        "data": initModalData,
        "compList": [],
        "action": initModalAction,
        "loaded": true,
        "show": initModalShow
    }
    const [modal, setModal] = useState(initModal);
    const handleModalClose = () => {
        setModal(prevState => ({
            ...prevState,
            "show": initModalShow
        }));
    }
    const handleModalShow = (event) => {
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "create"
                    },
                    "show": !initModalShow,
                    "loaded": true
                }));
                break;

            default:
                break;
        }
    }
    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                postUserData();
                break;

            case "edit":
                break;

            default:
                break;
        }

    }
    const onModalChange = (event, action) => {
        if (action !== undefined && action.name === "compartment-options") {
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...prevState.data,
                    "comp_id": event.id
                }
            }));
        } else {
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...prevState.data,
                    [event.target.name]: event.target.value
                }
            }));
        }
    }


    async function getUserList() {
        await apiAuthInstance({
            "url": "account/user-detail-admin/",
            "method": "get",
        }).then((response) => {
            setTableListData(response.data);
        });
    }
    async function postUserData() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "account/user-detail-admin/",
            "method": "post",
            "data": modal.data
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false
            }));
            getUserList();
        });
    }

    useEffect(() => {
        // getUserList
        apiAuthInstance({
            "url": "account/user-detail-admin",
            "method": "get",
        }).then((response) => {
            setTableListData(response.data);
        });
        apiAuthInstance({
            "url": "account/compartment-admin/",
            "method": "get",
            "params": {
                "comp_only": "true"
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                "compList": response.data.data
            }));
        });

    }, []);

    const [sort, setSort] = useState({ name: 'a_to_z', value: { key: 'email', type: 'text', aesc: false }})
    const sortOptions = [
        { name: 'a_to_z', value: { key: 'email', type: 'text', aesc: false }},
        { name: 'z_to_a', value: { key: 'email', type: 'text', aesc: true }},
        { name: 'create_time_new_to_old', value: { key: 'create_time', type: 'date', aesc: false }},
        { name: 'create_time_old_to_new', value: { key: 'create_time', type: 'date', aesc: true }},
    ]

    const [search, setSearch] = useState('')

    return (
        <div className="Table-DefaultHeight Table-AutoWidth">
            <Row xs="auto" className="mb-2">
            <div className="d-flex w-100">
                <Col>
                    <Button variant="orcaLight" name="create" onClick={handleModalShow} title="新增">
                        <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: 'none' }} className="pe-2" />
                        {t('create_user_account')}
                    </Button>
                </Col>
                <Form.Control className="w-25" type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search', { ns: 'common'})} />
                <Dropdown className="ps-3" size="sm" align="end">
                    <Dropdown.Toggle
                        className="btn-orcaLight"
                        id="dropdown-basic"
                    >
                        <FontAwesomeIcon icon={faArrowDownWideShort} />
                        &ensp;{t(`${sort.name}`, { ns: 'common'})}&ensp;
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {sortOptions.map((option, i) => 
                            <Dropdown.Item
                                key={`${i}_${option.name}`}
                                href=""
                                onClick={() => setSort(option)}
                            >
                                {t(`${option.name}`, { ns: 'common'})}
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            </Row>

            <Modal show={modal.show} size="lg">
                <Modal.Header closeButton onHide={handleModalClose}>
                    <Modal.Title>{t('create_user_account')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="px-4 py-4">
                        <Row className="h-100 w-100 m-0">
                            <Col xs={4} className="d-flex text-orcaLighter">
                                <FontAwesomeIcon className="m-auto" style={{ height: '7rem' }} icon={faUserPlus} />
                            </Col>
                            <Col xs={8}>
                                <Form.Group className="mb-3" controlId="formBasicEmail" >
                                    <Form.Label>{t(`email`, { ns: 'common'})}</Form.Label>
                                    <Form.Control name="email" type="email" placeholder="Type email of new user..." defaultValue={modal.data.email} onChange={onModalChange}/>
                                </Form.Group>
                                {authRank === "developer" && 
                                    <Form.Group className="mb-3" controlId="formBasicPhone">
                                        <Form.Label>{t(`compartment`, { ns: 'common'})}</Form.Label>
                                        <Select
                                            styles={purpleSelector}
                                            name="compartment-options"
                                            options={modal.compList.map((item) => ({ "value": item.comp_name, "label": item.corp_name + "/" + item.comp_name, "id": item.comp_id }))}
                                            onChange={onModalChange}
                                        />
                                    </Form.Group>
                                }

                            </Col>
                        </Row>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "Submit",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>
            <TableList settings={{
                tableConfig: [
                    {
                        "head": "comp_user",
                        "dataType": "text-center",
                        "fieldName": "email"
                    },
                    {
                        "head": "permission",
                        "dataType": "text-center",
                        "fieldName": "perm_name"
                    },
                    {
                        "head": "active",
                        "dataType": "boolean",
                        "fieldName": "is_active"
                    },
                    {
                        "head": "create_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "create_time"
                    },
                ],
                "data": tableListData.data && tableListData.data
                    .filter((t) => t.email.toLowerCase().includes(search.toLowerCase()))
                    .sort((a, b) => {
                        switch(sort.value.type) {
                            case 'date':
                                return sort.value.aesc ? new Date(a[sort.value.key]) - new Date(b[sort.value.key]) : new Date(b[sort.value.key]) - new Date(a[sort.value.key])
                            case 'text':
                                return !sort.value.aesc ? a[sort.value.key].charCodeAt(0) - b[sort.value.key].charCodeAt(0) : b[sort.value.key].charCodeAt(0) - a[sort.value.key].charCodeAt(0)
                            default:
                                return sort.value.aesc ? a[sort.value.key] - b[sort.value.key] : b[sort.value.key] - a[sort.value.key]
                        }
                    }),
                "size": 10,
                "pagination": true,
            }}
            />

        </div>

    );
}
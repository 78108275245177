import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Pagination from 'react-bootstrap/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faHourglass, faEdit, faPaperPlane ,faMagnifyingGlass, faChartColumn,faPause, faCloudArrowDown, faBan, faXmark, faF, faCopy, faCheckToSlot, faCircleInfo, faTags} from '@fortawesome/free-solid-svg-icons'
import {faFacebook } from '@fortawesome/free-brands-svg-icons'
export default function TableList(props) {
    const { settings } = props
    const { size, page, totalPage, onPageClick, pagination, loading } = settings
    const [pageSlice, setPageSlice] = useState(1)
    const { t, i18n } = useTranslation('TableList', 'common')

    useEffect(() => {
        const max = Math.ceil(settings.data.length / size) || 1
        if (pageSlice > max) setPageSlice(max)
    }, [settings.data, pageSlice])

    // dot control
    const [dot, setdot] = useState(" ·")
    useEffect(() => {
    setTimeout(() => {
    if (dot.length === 3 ) setdot("")
    else setdot(dot + "·")
    }, 550)
    }, [dot])

    if (loading) {
    return  <Row className="d-flex py-3 pt-5 h-100">
                <Form.Label className="text-center text-orcaMid my-auto pb-4">
                    <FontAwesomeIcon icon={faHourglass} className="load-icon h1" />
                    <br />
                    <h5 className="pt-3">
                        {t('loading')} {dot}
                    </h5>
                </Form.Label>
            </Row>
    }

    //page switcher
    const onPageClickSlice = (target) => {
        if (target <= Math.ceil(settings.data.length / size) && target >= 1) setPageSlice(target)
    }

    let sliceData = []
    if (size) sliceData = settings.data.slice(size * (pageSlice - 1), size * pageSlice)
    else sliceData = settings.data

    function tableData(item, itemIndex, data, dataIndex, rowId, clickToShowModal) {
    
        let dataField = data
        if (typeof (item.fieldName) === "string") {
            const fieldNames = item.fieldName.split(".");
            for (let i = 0; i < fieldNames.length; i++) {
                dataField = dataField[fieldNames[i]];
            }
        }
        switch (item.dataType) {
            
            case "payment":
                switch (data[item.type]) {
                    
                    case "deposit":
                        switch (data[item.status]) {
                            case "unpaid":
                                return (
                                <td
                                    key={itemIndex}
                                    style={{ textIndent : '5%' }}
                                >
                                    <Button variant="orca-search" title="檢視" name="checkDetail" value={data[item.fieldAsValueId]} onClick={item.onClick}>
                                        <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                    </Button>
                                    {item.is_developer && <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValueId]} onClick={item.onClick}>
                                        <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                                    </Button>}
                                </td>
                                )
                            case "paid":
                                return (
                                <td
                                    key={itemIndex}
                                    style={{ textIndent : '5%' }}
                                >
                                    {/* <Button variant="orca-search" title="檢視" name="checkDetail" value={data[item.fieldAsValueId]} onClick={item.onClick}> */}
                                    {/* <Button style={{visibility: 'hidden'}}>
                                        <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                    </Button> */}
                                    {item.is_developer && <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValueId]} onClick={item.onClick}>
                                        <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                                    </Button>}
                                </td>
                                )
                            default:
                                break
                        }
                        break
                    case "spend":
                        return (
                        <td
                            key={itemIndex}
                            style={{ textIndent : '5%' }}
                        >
                            {/* <Button variant="orca-search" title="檢視" name="checkDetail" value={data[item.fieldAsValueId]} onClick={item.onClick} style={{visibility: 'hidden'}}> */}
                            <Button style={{visibility: 'hidden'}}>
                                <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                            </Button>
                        </td>
                        )
                    default:
                        break
                }
                break

            //SmsSending + MailSending: Func.
            case "functions in different status":
                switch (item.channel) {

                    case "sms":
                        switch (data[item.status]) {
    
                            case "Save":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="檢視" name="options" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faPaperPlane} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
    
                            case "Schedule":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-unschedule" title="取消" name="scheduled-to-draft" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faPause} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
    
                            case "Sending":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="檢視" name="get-efficiency" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
    
                            case "Sent":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="View" name="get-efficiency" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
                            case "Processing":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        {/* <Button style={{visibility: 'hidden'}}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button> */}
                                        <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button>
                                    </td>
                                )
                            default:
                                break;
                        }
                        break;

                    case "email":
                        switch (data[item.status]) {
                            case "save" || "paused":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '0%' }}
                                    >
                                        <Button variant="orca-search" title="檢視" name="options" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faPaperPlane} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                                        </Button>
                                        {/* <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button> */}
                                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
    
                            case "Draft":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="檢視" name="options" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faPaperPlane} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                                        </Button>
                                        {/* <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button> */}
                                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
                                
                            case "Schedule":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        {/* <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button> */}
                                        <Button variant="orca-unschedule" title="取消" name="scheduled-to-draft" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faPause} className="fa-fw" />
                                        </Button>
                                        
                                    </td>
                                );
    
                            case "Sending":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="檢視" name="get-efficiency" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button>
                                        {/* <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button> */}
                                    </td>
                                );
    
                            case "Sent":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="檢視" name="get-efficiency" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button>
                                        {/* <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button> */}
                                    </td>
                                );
                            case "Processing":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        {/* <Button style={{visibility: 'hidden'}}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button> */}
                                        <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button>
                                    </td>
                                )
                            case "Event":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="檢視" name="reports" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
                            default:
                                break;
                        }
                        break;


                    case "whatsapp":
                        switch (data[item.status]) {
                            case "draft":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="檢視" name="options" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faPaperPlane} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
                                
                            case "on_schedule":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-unschedule" title="取消" name="scheduled-to-draft" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faPause} className="fa-fw" />
                                        </Button>
                                        
                                    </td>
                                );
    
                            case "sending":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="檢視" name="get-efficiency" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
    
                            case "sent":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="檢視" name="get-efficiency" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
                                case "delivered":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="檢視" name="get-efficiency" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
                            case "processing":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        {/* <Button style={{visibility: 'hidden'}}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button> */}
                                        <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                                        </Button>
                                    </td>
                                )
                            default:
                                break;
                        }
                        break;
    
                    case "line":
                        switch (data[item.status]) {
                            case "草稿":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{textAlign: 'center'}}
                                    >
                                        <Button variant="orca-search" title="發送" name="options" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faPaperPlane} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
                            case "排程中":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-unschedule" title="取消" name="scheduled-to-draft" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faPause} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
                                
                            case "已寄送":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="成效" name="get-efficiency" value={data[item.fieldAsValueCampaignId]} onClick={item.onLineEfficiencyClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button>
                                    </td>
                                );

                            case "未知":
                                return (
                                    <td
                                        key={itemIndex}
                                        id="Table-td-Resize"
                                        style={{ textIndent : '5%' }}
                                    >
                                    </td>
                                );
                            default:
                                break;
                        }
                        break;

                    case "ga4-ecomm":
                        return (
                            <td
                                key={itemIndex}
                                style={{ textIndent : '5%' }}
                            >
                                <Button variant="orca-search" title="建立再行銷標籤" name="options" value={data[item.fieldAsValueCampaignId]} onClick={item.onClick}>
                                    <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTags} className="fa-fw" />
                                </Button>
                            </td>
                        )
                        
                    case "workflow":
                        switch (data[item.status]) {
                            case "draft":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="執行" name="execute" value={data[item.fieldAsValueWorkflowId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faPaperPlane} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValueWorkflowId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValueWorkflowId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
                                
                            case "executing":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="檢視" name="view" value={data[item.fieldAsValueWorkflowId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-unschedule" title="取消" name="scheduled-to-draft" value={data[item.fieldAsValueWorkflowId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faPause} className="fa-fw" />
                                        </Button>
                                        
                                    </td>
                                );
    
                            case "suspended":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="執行" name="execute" value={data[item.fieldAsValueWorkflowId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faPaperPlane} className="fa-fw" />
                                        </Button>
                                        {/* <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValueWorkflowId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                                        </Button> */}
                                        <Button variant="orca-search" title="檢視" name="view" value={data[item.fieldAsValueWorkflowId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button>
                                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValueWorkflowId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
    
                            case "finished":
                                return (
                                    <td
                                        key={itemIndex}
                                        style={{ textIndent : '5%' }}
                                    >
                                        <Button variant="orca-search" title="檢視" name="view" value={data[item.fieldAsValueWorkflowId]} onClick={item.onClick}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                                        </Button>
                                    </td>
                                );
                            default:
                                break;
                        }
                        break
                    default:
                        break;
                }
                break;
    
        //Global basic (too much place...)
            case "text":
                return (
                    <OverlayTrigger
                        key={itemIndex}
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={String(dataField) &&
                            String(dataField).length > 0 ? (
                            <Tooltip className="Tips">
                                {String(dataField)}
                            </Tooltip>
                        ) : (<React.Fragment />)}
                    >
                        <td
                            key={itemIndex}
                            value={rowId ? data[rowId] : dataIndex}
                            name="dataField"
                            onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        >
                            {String(dataField).length <= 17 ? String(dataField) : `${String(dataField).substring(0, 17)}...`}
                        </td>
                    </OverlayTrigger>
                );
    
        //OrcaTags + Tags + Directories: Customer; Admin: Perm; Customer: Phone; SmsAnalytics + MailAnalytics: numbers;
            case "text-center":
                return (
                    <td
                        className={(dataField === 'unpaid') ? ('text-red') : ('orca')}
                        key={itemIndex}
                        value={rowId ? data[rowId] : dataIndex}
                        name="dataField"
                        onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        style={{ textAlign: 'center' }}
                    >
                        {t(`${String(dataField)}`, {ns: 'common'}).length <= 10 ? t(`${String(dataField)}`, {ns: 'common'}) : `${t(`${String(dataField)}`, {ns: 'common'}).substring(0, 10)}...`}
                    </td>
                );
    
            case "text-end":
                return (
                    <td
                        key={itemIndex}
                        value={rowId ? data[rowId] : dataIndex}
                        name="dataField"
                        onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        style={{ textAlign: 'end' }}
                    >
                        {String(dataField).length <= 10 ? String(dataField) : `${String(dataField).substring(0, 10)}...`}<span className="pe-3"/>
                    </td>
                );
            
                case "amount":
                    return (
                        <OverlayTrigger
                            key={itemIndex}
                            placement="bottom-end"
                            delay={{ show: 250, hide: 400 }}
                            overlay={String(dataField) &&
                                String(dataField).length > 0 ? (
                                <Tooltip className="Tips">
                                    {String(dataField)}
                                </Tooltip>
                            ) : (<React.Fragment />)}
                        >
                        <td
                            key={itemIndex}
                            value={rowId ? data[rowId] : dataIndex}
                            name="dataField"
                            onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                            style={{ textAlign: 'end' }}
                        >
                            <span style={{ paddingRight: '10%' }}>
                                {String(dataField)}
                            </span>
                        </td>
                        </OverlayTrigger>
                    );
    
            case "percent":
                const checkZero = (string) => {
                    const pivot = string.indexOf('.')
                    if (pivot === -1) return `${string}.00`
                    else return `${string}${'0'.repeat(Math.max(3 - (string.length - pivot), 0))}`
                }
                return (
                    <td
                        key={itemIndex}
                        value={rowId ? data[rowId] : dataIndex}
                        name="dataField"
                        onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        style={{ textAlign: 'end' }}
                    >
                        {checkZero(String(dataField))} <span className="pe-3"/>
                    </td>
                );
    
        //UserManagement: Orca, Active, Staff, Admin;
            case "boolean":
                return (
                    <td
                        className={dataField ? 'text-success' : 'text-secondary'}
                        key={itemIndex}
                        value={rowId ? data[rowId] : dataIndex}
                        name="dataField"
                        onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        style={{ fontWeight: "600", textAlign: "center" }}
                    >
                        ● {String(dataField)}
                    </td>
                );
    
        //SmsSending: Status
            case "status":
                return (
                    <td
                        className={(dataField === 'Sent'|| dataField === 'Delivered' || dataField === '已寄送' || dataField === 'sent' || dataField === 'approved') ? ('text-success') : (dataField === 'Schedule' || dataField === 'Scheduled' || dataField === '排程中' || dataField === 'on_schedule' || dataField === 'pending') ? ('text-blue') : (dataField === 'Paused') ? ('text-red') : ('text-grey')}
                        key={itemIndex}
                        value={rowId ? data[rowId] : dataIndex}
                        name="dataField"
                        onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        style={{textAlign: 'center'}}
                    >
                        {`● ${String(dataField) === "Save" || String(dataField) === "Pause" || String(dataField) === "draft" || String(dataField) === "Draft" ? (
                                `${t('draft', { ns: 'common'})}`
                            ) : String(dataField) === "Schedule" || String(dataField) === "Scheduled" || String(dataField) === "on_schedule" ? (
                                `${t('on_schedule', { ns: 'common'})}`
                            ) : String(dataField) === "Sent" || String(dataField) === 'delivered' || String(dataField) === 'sent' ? (
                                `${t('delivered', { ns: 'common'})}`
                            ) : String(dataField) === "Sending" || String(dataField) === "sending" ? (
                                `${t('sending', { ns: 'common'})}`
                            ) : String(dataField) === "Processing" ? (
                                `${t('sending', { ns: 'common'})}`
                            ) : (
                                // String(dataField)
                                `${t(String(dataField), { ns: 'common'})}`
                            )}
                        `}
                    </td>
                );
    
        //SmsSending: Send Time / Edit Time, Customers: Reveived Time, Reveived Time, Open Time, Click Time; Tags: Created, Updated; SmsSending: Send Time / Edit Time; MailSending: Send Time, MailTemplate: Create Time;
            case "datetime":
                return (
                    <td
                        key={itemIndex}
                        value={rowId ? data[rowId] : dataIndex}
                        name="dataField"
                        onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        style={{ textAlign: "center" }}
                    >
                        {String(dataField).slice(0, 10)} {String(dataField).slice(11, 19)}
                    </td>
                );
            case "datetime-tz+8":
                return (
                    <td
                        key={itemIndex}
                        value={rowId ? data[rowId] : dataIndex}
                        name="dataField"
                        onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        style={{ textAlign: "center" }}
                    >
                        { dataField !== ""  ? new Date(dataField).toLocaleString('sv-SE', {timeZone: 'Asia/Taipei', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'}) : "–"}
                    </td>
                );
        //Customers: Tags; Directories: Channels, Tags;
            case "list":
                let dataField_string = dataField.join(",")
                return (
                    <OverlayTrigger
                        key={itemIndex}
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={dataField_string &&
                            dataField_string.length > 0 ? (
                            <Tooltip className="Tips">
                                {dataField_string}
                            </Tooltip>
                        ) : (<React.Fragment />)}
                    >
                        <td
                            key={itemIndex}
                            value={rowId ? data[rowId] : dataIndex}
                            name="dataField"
                            onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        >
                            {dataField_string}
                        </td>
                    </OverlayTrigger>
                );
    
        //Directories: Orca Customers, Orca Tags;
            case "list-map":
                return (
                    <OverlayTrigger
                        key={itemIndex}
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={dataField.map(element => element[item.fieldNameMap]).join(",") &&
                            dataField.map((element, index) =>
                                element[item.fieldNameMap]).join(",").length > 0 ? (<Tooltip className="Tips">
                                    {dataField.map(element => element[item.fieldNameMap]).join(",")}
                                </Tooltip>
                        ) : (<React.Fragment />)}
                    >
                        <td
                            key={itemIndex}
                            value={rowId ? data[rowId] : dataIndex}
                            name="dataField"
                            onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        >
                            {dataField.map(element => element[item.fieldNameMap]).join(",")}
                        </td>
                    </OverlayTrigger>
                );

        //Backlists
        case "unban":
            return (
                <td
                    key={itemIndex}
                    className="text-center"
                >
                    <Button variant="orca-unban" title="解除黑名單" name="delete" value={data[item.fieldAsValue]} onClick={item.onDeleteClick}>
                        <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faXmark} className="fa-fw" />
                    </Button>
                </td>
            );


        //Tags + SmsTemplate + MailTemplate: Func.
            case "update and delete":
                return (
                    <td
                        key={itemIndex}
                        className="text-center"
                    >
                        <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValue]} onClick={item.onUpdateClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                        </Button>
                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValue]} onClick={item.onDeleteClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                        </Button>
                    </td>
                );
            case "update and replicate and delete":
                return (
                    <td
                        key={itemIndex}
                        className="text-center"
                    >
                        <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValue]} onClick={item.onUpdateClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                        </Button>
                        <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValue]} onClick={item.onReplicateClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                        </Button>
                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValue]} onClick={item.onDeleteClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                        </Button>
                    </td>
                );

            // Whatsapp Template
            case "view and delete and approve":
                return (
                    <td
                        key={itemIndex}
                        className="text-center"
                    >
                        <Button variant="orca-search" title="檢視" name="view" value={data[item.fieldAsValue]} onClick={item.onClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                        </Button>
                        {data.status === 'draft' && <Button variant="orca-search" title="審核" name="approve" value={data[item.fieldAsValue]} onClick={item.onClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCheckToSlot} className="fa-fw" />
                        </Button>}
                        {/* <Button variant="orca-delete" title="複製" name="replicate" value={data[item.fieldAsValue]} onClick={item.onReplicateClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                        </Button> */}
                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValue]} onClick={item.onClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                        </Button>
                    </td>
                );
            //Short Link: Func.
            case "update and copy and delete":
                return (
                    <td
                        key={itemIndex}
                        className="text-center"
                    >
                        <Button
                            variant="orca-search"
                            title="複製短網址"
                            name="copy"
                            value={data[item.fieldAsValue]}
                            onClick={() => {navigator.clipboard.writeText(data.short_url)}}
                        >
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCopy} className="fa-fw" />
                        </Button>

                        <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValue]} onClick={item.onUpdateClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                        </Button>
    
                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValue]} onClick={item.onDeleteClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                        </Button>
                    </td>
                );
    
        // OrcaTags: Func. 
            case "View":
                return (
                    <td
                        key={itemIndex}
                        className="text-center"
                    >
                        <Button
                            variant="orca-edit"
                            title="檢視"
                            name="dataField"
                            value={data[item.fieldAsValue]} 
                            onClick={settings.clickToShowModal !== undefined ? settings.clickToShowModal : () => (false)}
                        >
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                        </Button>
                    </td>
                );
    
        // Directories: Func.
        case "update and delete and download":
            return (
                <td
                    key={itemIndex}
                    className="text-center"
                >
                    <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValue]} onClick={item.onUpdateClick}>
                        <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                    </Button>
    
                    {data.csv_url ? 
                    <a href={data.csv_url}>
                        <Button variant="orca-delete" title="下載" name="download" value={data[item.fieldAsValue]}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCloudArrowDown} className="fa-fw" />
                        </Button>
                    </a>:
                        <Button variant="orca-delete" title="下載" name="download" value={data[item.fieldAsValue]}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCloudArrowDown} className="fa-fw" />
                        </Button>
                    }
                    
                    <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValue]} onClick={item.onDeleteClick}>
                        <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                    </Button>
                </td>
            );
    
        //Customers: Func.
            case "update and efficiency and delete":
                return (
                    <td
                        key={itemIndex}
                        className="text-center"
                    >
                        <Button variant="orca-edit" title="檢視成效" name="efficiency" value={data[item.fieldAsValue]} onClick={item.onUpdateClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faChartColumn} className="fa-fw" />
                        </Button>
    
                        <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValue]} onClick={item.onUpdateClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                        </Button>
    
                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValue]} onClick={item.onDeleteClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                        </Button>
                    </td>
                );

            //Customers: Func.
            case "update and efficiency and delete and ban":
                return data.blocked
                    ? <td
                        key={itemIndex}
                        className="text-center"
                    >
                        <Button variant="orca-unban" title="解除黑名單" name="unblock" value={data[item.fieldAsValue]} onClick={item.onDeleteClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faBan} className="fa-fw" />
                        </Button>    
                        {/* <Button variant="orca-unban" title="解除黑名單" name="delete" value={data[item.fieldAsValue]} onClick={item.onDeleteClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faXmark} className="fa-fw" />
                        </Button> */}
                    </td> : <td
                        key={itemIndex}
                        className="text-center"
                    >
                        <Button variant="orca-edit" title="檢視成效" name="efficiency" value={data[item.fieldAsValue]} onClick={item.onUpdateClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faChartColumn} className="fa-fw" />
                        </Button>

                        <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValue]} onClick={item.onUpdateClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                        </Button>

                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValue]} onClick={item.onDeleteClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                        </Button>

                        <Button variant="orca-ban" title="封鎖" name="ban" value={data[item.fieldAsValue]} onClick={item.onBanClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faBan} className="fa-fw" />
                        </Button>
                    </td>

            case "shopify abandon checkout":
                return (
                    <td
                        key={itemIndex}
                        className="text-center"
                    >
                        <Button variant="orca-edit" title="轉換" name="transform" value={data[item.fieldAsValue]} onClick={item.onTransformClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faEdit} className="fa-fw" />
                        </Button>
                    </td>
                );
            case "update and delete and download and facebook":
                return (
                    <td
                        key={itemIndex}
                        className="text-center"
                    >
                        <Button variant="orca-edit" title="編輯" name="edit" value={data[item.fieldAsValue]} onClick={item.onUpdateClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faMagnifyingGlass} className="fa-fw" />
                        </Button>
        
                        {data.csv_url && (item.userAuth === 'developer' || item.userAuth === 'overalluser' || item.can_download_directory_csv) ?
                        <a href={!item.is_free && data.csv_url}>
                            <Button variant="orca-delete" title="下載" name="download" value={data[item.fieldAsValue]} disabled={item.is_free}>
                                <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCloudArrowDown} className="fa-fw" />
                            </Button>
                        </a>:
                            null
                            // <Button variant="orca-delete" title="下載" name="download" value={data[item.fieldAsValue]} disabled={item.is_free}>
                            //     <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faCloudArrowDown} className="fa-fw" />
                            // </Button>
                        }
                        
                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValue]} onClick={item.onDeleteClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                        </Button>

                        <Button variant="orca-facebook" title="Facebook" name="select-facebook-account" value={data[item.fieldAsValue]} onClick={item.onFacebookClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faFacebook} className="fa-fw" />
                        </Button>
                    </td>
                );
            case "resend and delete":
                return (
                    <td
                        key={itemIndex}
                        className="text-center"
                    >
                        {!data.is_active && <Button variant="orca-edit" title="重新寄發" name="resend" value={data[item.fieldAsValue]} onClick={item.onResendClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faPaperPlane} className="fa-fw" />
                        </Button>}
                        <Button variant="orca-delete" title="刪除" name="delete" value={data[item.fieldAsValue]} onClick={item.onDeleteClick}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} icon={faTrashCan} className="fa-fw" />
                        </Button>
                    </td>
                );
            default:
                break;
        }
    }

    //page loading effect
    return  <React.Fragment>
                <div className="TableOverflow">
                    <Table bordered hover className="bg-light text-orca TableForms">
                        <thead>
                            <tr>
                                {settings.tableConfig.map((item, index) => (
                                    <th title={item.head} key={index}
                                        // 想要把標籤欄位寬度加長，但是substring也要跟著調，怕影響到其他表格，所以先不用
                                        // style={{ width: item.fieldName === 'tag_name' ? '300px' : undefined }} 
                                    >{t(`${item.head}`)}
                                    {item.head === 'amount_after_tax' && 
                                        
                                        <OverlayTrigger
                                            placement="left"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip className="Tips-rule d-flex">
                                                    <p className="text-start p-2 my-auto">
                                                        類型為“花費”，本欄為花費金額。因系統限制，金額並非即時數字，最晚會在寄發活動後 24 小時內取得實際寄發人數及花費金額。
                                                        <hr/>
                                                        類型為“儲值”，本欄為可用金額。
                                                        <hr/>
                                                        可用金額（四捨五入取整數） <br/>= 匯款金額 / (100%+稅率) <br/>
                                                    </p>
                                                </Tooltip>
                                            }>
                                            <span>
                                                &ensp;
                                                <FontAwesomeIcon icon={faCircleInfo} className="text-orcaLight ruleInfo" />
                                            </span>
                                        </OverlayTrigger>
                                        
                                    }</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sliceData.map((data, dataIndex) => (
                                    <tr key={dataIndex}>
                                        {
                                            settings.tableConfig.map((item, itemIndex) => (
                                                tableData(item, itemIndex, data, dataIndex, settings.rowId)
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>

                {pagination &&
                <div className="d-flex mt-auto w-100">
                    <div id="TablePagination" className="w-100">
                        <Row className="w-100 h-100 mx-auto">
                            <Pagination className="mb-0 px-0 d-flex">
                                <Pagination.Prev className="prev w-100" name="prev" onClick={() => { if (onPageClick) onPageClick(page - 1); else onPageClickSlice(pageSlice - 1) }} />
                                <Pagination.Item active className="w-100 text-center">
                                    {totalPage ? (
                                        <div className="w-100 h-75 bg-orca rounded-3 mx-auto">{page || pageSlice}／{totalPage}</div>
                                    ) : (
                                        <div className="w-100 h-75 bg-orca rounded-3 mx-auto">{page || pageSlice}／{Math.ceil(settings.data.length / size) || 1}</div>
                                    )}
                                </Pagination.Item>
                                <Pagination.Next className="next w-100" name="next" onClick={() => { if (onPageClick) onPageClick(page + 1); else onPageClickSlice(pageSlice + 1) }} />
                            </Pagination>
                        </Row>
                    </div>
                </div>
                }

            </React.Fragment>
}
